import { defineStore } from 'pinia';
import { useAuthenticationStore } from '@/store/authentication';
import { useAuthorizationStore } from '@/store/authorization';
import MerchantApi, { Merchant } from '@/api/merchant-service/merchant';
import { useBillingStore } from '@/store/billing';

const api = new MerchantApi();

type State = {
  merchants: Merchant[];
  merchantsFetched: boolean;
  currentMerchant?: Merchant;
  overridden: boolean;
};

const state = (): State => ({
  merchants: [],
  merchantsFetched: false,
  currentMerchant: undefined,
  overridden: false,
});

const _hasMerchantBeenActivated = (merchant: Merchant) => merchant.status !== 'inactive' && merchant.kycOnboardingStatus === 'verified';
const _verifiedAndActiveMerchants = ($this: State) => $this.merchants.filter((merchant: Merchant) => _hasMerchantBeenActivated(merchant));
const getters = {
  isCurrentMerchantVerified(this: State) {
    return this.currentMerchant?.kycOnboardingStatus === 'verified';
  },
  verifiedAndActiveMerchants(this: State) {
    return _verifiedAndActiveMerchants(this);
  },
  currentMerchantReviewOfDateDeadline(this: State) {
    const date = new Date(this.currentMerchant?.kycReviewOfDataDeadline ?? '');
    return isNaN(date.getTime()) ? undefined : date;
  },
};

const actions = {
  async initialize(this: State, activeOnly = true) {
    const fetchedMerchants = await api.fetchMerchants();
    useAuthorizationStore().initWithAssociatedMerchants(fetchedMerchants);
    this.merchantsFetched = true;
    this.merchants = fetchedMerchants.sort((a, b) => a.legalEntityName.localeCompare(b.legalEntityName));

    const loadedCurrentMerchantId = localStorage.getItem(`${useAuthenticationStore().user.email}:currentMerchant`);
    let selectedMerchant = this.merchants.find((merchant: Merchant) => merchant.merchantId === loadedCurrentMerchantId);
    if (activeOnly && selectedMerchant && !_hasMerchantBeenActivated(selectedMerchant)) {
      localStorage.removeItem(`${useAuthenticationStore().user.email}:currentMerchant`);
      selectedMerchant = undefined;
    }
    const firstVerifiedMerchant = _verifiedAndActiveMerchants(this)[0];
    this.currentMerchant = selectedMerchant ?? firstVerifiedMerchant ?? this.merchants[0];
    if (this.currentMerchant) {
      useAuthorizationStore().setMerchant(this.currentMerchant);
      await useBillingStore()?.initialize();
    }
  },
  startNewMerchant(this: State) {
    this.currentMerchant = undefined;
  },
  async switch(this: State, merchantId?: string) {
    if (!merchantId) {
      return;
    }
    const selectedMerchant = this.merchants.find((merchant: Merchant) => merchant.merchantId === merchantId);
    if (!selectedMerchant) {
      throw new Error(`Merchant ${merchantId} not found`);
    }
    this.currentMerchant = selectedMerchant;
    this.overridden = false;
    localStorage.setItem(`${useAuthenticationStore().user.email}:currentMerchant`, merchantId);
    useAuthorizationStore().setMerchant(selectedMerchant);
    await useBillingStore().initialize();
  },
  async override(this: State, merchantId: string) {
    if (!this.currentMerchant) {
      throw new Error('Current merchant is not set');
    }
    this.currentMerchant.merchantId = merchantId;
    this.currentMerchant.legalEntityName = 'OVERRIDDEN';
    // We can leave the other properties as it is
    this.overridden = true;
    await useBillingStore().initialize();
  },
  async transfer(this: State, merchantId: string, email: string) {
    await api.transferMerchant(merchantId, email);
  },
  async update(this: State, attributes: Pick<Merchant, 'financeEmail'>) {
    await api.updateMerchant(this.currentMerchant!.merchantId, attributes);
    this.currentMerchant = {
      ...this.currentMerchant!,
      ...attributes,
    };
  },
  hasMerchantBeenActivated(this: State, merchant: Merchant) {
    return _hasMerchantBeenActivated(merchant);
  },
  hasMerchantChurned(this: State, merchant: Merchant) {
    return merchant.status === 'churned';
  },
};

export const useMerchantsStore = defineStore('merchants', {
  state,
  getters,
  actions,
});
