<template>
  <v-banner
    v-if="showBanner"
    text="Your account has been deactivated. You can only see historical data from your company."
    sticky
    style="z-index: 9999"
    bg-color="error"
  >
    <template #prepend><v-icon class="mt-4">mdi-alert-circle-outline</v-icon></template>
  </v-banner>
  <div v-else></div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useMerchantsStore } from '@/store/merchants';

const merchantsStore = useMerchantsStore();
const showBanner = computed(() => merchantsStore.currentMerchant && merchantsStore.hasMerchantChurned(merchantsStore.currentMerchant));
</script>
