<template>
  <v-chip color="primary" size="large"><v-icon icon="mdi-bookmark-check" /> &nbsp; {{ merchantsStore.currentMerchant?.legalEntityName }}</v-chip>

  <BillingEmail v-if="auth.hasCompanyScoped(Permission.Company_UpdateDetails)" class="my-4" />

  <v-expansion-panels v-if="canDeactivate && posthog?.isFeatureEnabled('churn')" class="mt-16">
    <v-expansion-panel
      expand-icon="mdi-store-remove-outline"
      collapse-icon="mdi-store-remove-outline"
      @group:selected="({ value }) => value && stepDeactivationFlow('expand')"
    >
      <v-expansion-panel-title>
        <span class="text-error">Deactivating your account</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <div v-if="customerInContract">
          <p>You are currently in a contractual agreement.</p>
          <br />
          <p>Please contact us at <a href="mailto:help@yetipay.me">help@yetipay.me</a> if you would like to discuss your terms.</p>
        </div>

        <div v-else>
          <p>You will not be able to trade with your terminal anymore.</p>
          <p class="mt-4 d-flex justify-end">
            <v-btn color="error" :loading="loading" prepend-icon="mdi-delete" @click="stepDeactivationFlow('dialog', () => (isDialogActive = true))">
              Deactivate
            </v-btn>
          </p>
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>

  <v-dialog v-model="isDialogActive" max-width="500px">
    <v-card>
      <template #title>
        <h3 v-if="currentStep === Step.Final" class="text-error">Actions required</h3>
        <h3 v-else class="text-error">Deactivating your account</h3>
      </template>
      <template #text>
        <v-window v-model="currentStep">
          <v-window-item :value="Step.Confirm">
            <p>
              <v-combobox v-model="reason" :items="[...possibleChurnReasons, 'Other']" label="Why do you want to cancel?" />
              <transition>
                <v-text-field v-if="reason === 'Other'" v-model="otherReason" label="Tell us more?" />
              </transition>
            </p>
            <br />
            <p>You will not be able to trade with your terminal anymore.</p>
            <br />
            <p>Please return your terminal by following the instructions in the email you will receive.</p>
          </v-window-item>

          <v-window-item :value="Step.Final">
            <p>Your account is now deactivated.</p>
            <br />
            <p>
              Please send us back your device using the following link:
              <a href="https://www.royalmail.com/track-my-return#/details/5864">https://www.royalmail.com/track-my-return#/details/5864</a>
            </p>
            <br />
            <p>You will keep being billed until your device is returned to Yetipay.</p>
          </v-window-item>
        </v-window>
      </template>
      <template #actions>
        <v-window v-model="currentStep" :touch="false" class="d-flex flex-fill">
          <!-- if a class is present, the window item will always show... -->
          <v-window-item :value="Step.Confirm" :class="currentStep === Step.Confirm ? 'd-flex' : ''">
            <v-btn-nd text="Cancel" class="ms-4 my-4" @click="isDialogActive = false" />
            <v-spacer />
            <v-btn
              color="error"
              class="bg-error on-error me-4 my-4"
              text="Deactivate"
              :loading="loading"
              @click="stepDeactivationFlow('confirm', () => (currentStep = Step.Final))"
            />
          </v-window-item>

          <v-window-item :value="Step.Final" class="d-flex">
            <v-spacer />
            <v-btn class="bg-primary on-primary me-4 my-4" text="Close" @click="closeFinalStep" />
          </v-window-item>
        </v-window>
      </template>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, inject, computed, onMounted } from 'vue';
import { useAuthorizationStore, Permission } from '@/store/authorization';
import { useMerchantsStore } from '@/store/merchants';
import { API } from '@/plugins/api';
import { PostHog } from 'posthog-js';

const auth = useAuthorizationStore();
const merchantsStore = useMerchantsStore();

const posthog = inject<PostHog>('posthog');
const api = inject<API>('api');

enum Step {
  Confirm = 'confirm',
  Final = 'final',
}
const possibleChurnReasons = ['I am closing my business', 'I am moving to a different provider', 'I am not satisfied with the service'];

const canDeactivate = computed(
  () =>
    (!merchantsStore.currentMerchant || !merchantsStore.hasMerchantChurned(merchantsStore.currentMerchant)) &&
    auth.hasCompanyScoped(Permission.Company_Deactivate) &&
    auth.hasCompanyScoped(Permission.Company_GetRates),
);
const customerInContract = ref<boolean | undefined>(false);
const isDialogActive = ref(false);
const reason = ref('');
const otherReason = ref('');
const realReason = computed(() => (reason.value === 'Other' ? otherReason.value : reason.value));
const currentStep = ref(Step.Confirm);
const loading = ref(false);

onMounted(async () => {
  if (!canDeactivate.value) {
    return;
  }
  const deal = await api!.merchant.getDeal(merchantsStore.currentMerchant!.merchantId);
  customerInContract.value = (deal.contractEndDate ?? '') > new Date().toISOString();
});

const stepDeactivationFlow = async (step: 'expand' | 'dialog' | 'confirm', callback?: () => void) => {
  if (step === 'expand' && !customerInContract.value) {
    return;
  }
  const stage = step === 'expand' ? 'in-contract' : step === 'dialog' ? 'init' : 'complete';
  const reason = step === 'confirm' && realReason.value ? realReason.value : undefined;
  loading.value = true;
  try {
    await api!.merchant.deactivate(merchantsStore.currentMerchant!.merchantId, stage, reason);
    if (callback) {
      callback();
    }
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
};
const closeFinalStep = () => {
  setTimeout(() => (currentStep.value = Step.Confirm), 300); // So that users don't see the final step before the fade out
  isDialogActive.value = false;
};
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

div.v-window >>> div.v-window__container {
  display: flex;
  flex-grow: 1;
}
</style>
